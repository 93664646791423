export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
        `Сайт был обновлен. ` +
        `Перезагрузить?`
    )
    if (answer === true) {
        window.location.reload()
    }

}

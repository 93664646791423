// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-jsx": () => import("./../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-page-jsx": () => import("./../src/pages/MainPage.jsx" /* webpackChunkName: "component---src-pages-main-page-jsx" */),
  "component---src-pages-your-bakery-jsx": () => import("./../src/pages/your-bakery.jsx" /* webpackChunkName: "component---src-pages-your-bakery-jsx" */),
  "component---src-templates-product-group-jsx": () => import("./../src/templates/ProductGroup.jsx" /* webpackChunkName: "component---src-templates-product-group-jsx" */),
  "component---src-templates-product-jsx": () => import("./../src/templates/Product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

